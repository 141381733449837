import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import './index.css';
// import App from './App';
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss?v=1.4.0";
import "./assets/scss/custom.scss";
import reportWebVitals from './reportWebVitals';

import Home from "./views/Home.js";
import GuitarPage from 'views/GuitarPage';
import SignUp from 'views/SignUp';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/home" render={(props) => <Home {...props} />} />
        <Route
          path="/guitars"
          render={(props) => <GuitarPage {...props} />}
        />
         <Route path="/contactus" render={(props) => <SignUp {...props} />} />
        <Redirect to="/home" />
        <Redirect from="/" to="/home" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
