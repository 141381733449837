
export const photos = [
    {
      src: require('assets/img/32fret4.jpg').default,
      width: 4,
      height: 3,
      caption: '32 Frets',
    },
    {
        src: require('assets/img/nick-mazoni2.jpg').default,
        width: 1,
        height: 1,
        caption: 'Nick Mazoni',
    },
    {
        src: require('assets/img/larina-sg4.jpg').default,
        width: 4,
        height: 3,
        caption: 'Larina SG'
    },
    {
        src: require('assets/img/larina-sg-v7.jpg').default,
        width: 3,
        height: 4,
        caption: 'Larina SG'
    },
    {
        src: require('assets/img/larina-sg1.jpg').default,
        width: 4,
        height: 3,
        caption: 'Larina SG'
    },
    {
        src: require('assets/img/larina-sg-v9.jpg').default,
        width: 3,
        height: 4,
        caption: 'Larina SG'
    },
    {
        src: require('assets/img/nick-mazoni1.jpg').default,
        width: 4,
        height: 3,
        caption: 'Nick Mazoni'
    },
    {
        src: require('assets/img/tim-crogan1.jpg').default,
        width: 4,
        height: 3,
        caption: 'Tim Crogan'
    },
    {
        src: require('assets/img/larina-sg6.jpg').default,
        width: 4,
        height: 3,
        caption: 'Larina SG'
    },
    {
        src: require('assets/img/tim-crogan3.jpg').default,
        width: 4,
        height: 2,
        caption: 'Tim Crogan'
    },
    {
        src: require('assets/img/32fret5.jpg').default,
        width: 4,
        height: 2,
        caption: '32 Frets'
    },
    {
        src: require('assets/img/larina-sg5.jpg').default,
        width: 4,
        height: 2,
        caption: 'Larina SG'
    }
];

