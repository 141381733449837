import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Navbar from "../components/navbar/ChaseNavbar.js";
import DarkFooter from "../components/footer/DarkFooter.js";
import CommonHeader from "../components/header/CommonHeader.js";
import GalleryShow from "../components/GalleryShow/GalleryShow.js";
import { photos } from "./photos";

function GuitarPage() {
    return (
        <>
          <Navbar />
          <div className="wrapper">
            <CommonHeader title="Experience Building Custom Guitars" 
            description="Pick your fantasy and chat with me"/>

            <div className="section section-about-us dark-theme">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    
                    <h5 className="description white-text">
                    Bring your dream and ideas, choose from a wide range of settings, 
                    including shape design, woods, colors, finish, hardware, construction methods and 
                    even your own artwork. Once you are done, you can purchase your own unique guitar.
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="12">
                    <GalleryShow images={photos}    backdropClosesModal={true}/>
                  </Col>
                </Row>
                <div className="separator separator-primary"></div>
                
              </Container>
            </div>
          
            <DarkFooter />
          </div>

      </>
    );
  }
  
  export default GuitarPage;
  