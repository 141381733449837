import React, { useState, useCallback,Component } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Modal,Col, Row,
  ModalBody,
} from 'reactstrap';
//import Lightbox from 'react-images';
import Lightbox from 'react-images-texts-videos';
import PropTypes from 'prop-types';
import styles from './GalleryShow.module.css';
import { css, StyleSheet,classes } from "aphrodite/no-important";
import { photos } from "../../views/photos";

class GalleryShow extends Component {

  constructor(props) {
    super(props);

    let itemType;

    if (this.props.images) {
      itemType = "images";
    } else if (this.props.texts) {
      itemType = "texts";
    } else {
      itemType = "videos";
    }

    this.state = {
      lightboxIsOpen: false,
      currentItem: 0,
      itemType: itemType
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoItem = this.gotoItem.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }


    gotoPrevious() {
      this.setState({
        currentItem: this.state.currentItem - 1
      });
    }
  
    gotoNext() {
      this.setState({
        currentItem: this.state.currentItem + 1
      });
    }
    openLightbox(event, obj) {
      this.setState({
        currentItem: obj.index,
        lightboxIsOpen: true,
      });
    }
    

  closeLightbox() {
    this.setState({
      currentItem: 0,
      lightboxIsOpen: false
    });
  }

  
  gotoItem(index) {
    this.setState({
      currentItem: index
    });
  }

  handleClickItem() {
    if (this.state.currentItem === this.props[this.state.itemType].length - 1)
      return;

    this.gotoNext();
  }




    renderGallery() {
    
        const { images } = this.props;
  
        if (!images) return;
  
        let gallery;
  
        if (this.props.singleItem) {
          gallery = images
            .map((obj, i) => {
              return (
                <a
                  href={obj.src}
                  className={css(classes.thumbnail, classes[obj.orientation])}
                  onClick={e => this.openLightbox(e, i)}
                  key={i}
                >
                  <div className="img-thumbnail">
                    <img
                      src={obj.thumbnail}
                      className={css(classes.source)}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                </a>
              );
            });
          return <div className={css(classes.gallery)}>{gallery}</div>;
        } else {
          gallery = images
            .map((obj, i) => {
              return (
                <Col xl={2} lg={3} md={4} sm={6} xs={12} key={i} className="mb-1">
                  <a
                    href={obj.src}
      
                    onClick={e => this.openLightbox(e, i)}
                  >
                    <div className="img-thumbnail">
                      <img
                        src={obj.src}

                        style={{ width: "100%" }}
                        alt=""
                      />
                    </div>
                  </a>
                </Col>
              );
            });
          return (
            <div >
              <Row>{gallery}</Row>
            </div>
          );
        }
      
    }




    render() {
      return (
        <>
        <div className="section dark-theme">
          <h2>CUSTOM BUILD COLLECTIONS</h2>
        <Gallery photos={photos} direction={"column"}  onClick={this.openLightbox}/>
        {/* {this.renderGallery()} */}
        <Lightbox
          items={{type:'images', items: photos }}
          currentItem={this.state.currentItem}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickItem}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoItem}
          onClose={this.closeLightbox}
          showThumbnails={this.props.showThumbnails}
          theme={this.props.theme}
          backdropClosesModal={this.props.backdropClosesModal}
        />
        </div>
        </>
      );
    }
  }

  // GalleryShow.propTypes = {};

  // GalleryShow.defaultProps = {};

export default GalleryShow;

/*
      <>
      <Gallery photos={photos} onClick={openLightbox} />
      <Modal isOpen={modal1} toggle={() => setModal1(false)}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
      </Modal>
      </>
*/


/*
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
                    <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators items={photos} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
          </Modal>
        ) : null}
      </ModalGateway>
*/

/*
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <Gallery photos={photos} onClick={openLightbox} />

        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
*/