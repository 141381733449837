import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
//import Carousel, { Modal, ModalGateway } from "react-images";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import ProfilePageHeader from "components/header/ProfilePageHeader.js";
import DefaultFooter from "../components/footer/DefaultFooter.js";
import DarkFooter from "../components/footer/DarkFooter.js";
import Navbar from "../components/navbar/ChaseNavbar.js";
import GalleryShow from "../components/GalleryShow/GalleryShow.js";
import { photos } from "./photos";

function Home() {

  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

    return (
        <>
            <Navbar />
            <div className="wrapper">
        <ProfilePageHeader />
        <div className="section home-section">
          <Container>
            <div className="button-container">
              <Button className="btn-round" color="info" size="lg">
                Follow
              </Button>
              <Button
                href="https://www.facebook.com/ChaseYourGuitars"
                target="_blank"
                className="btn-round btn-icon"
                color="default"
                id="tooltip515203352"
                size="lg"
              >
                <i className="fab fa-facebook"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip515203352">
                Follow me on Facebook
              </UncontrolledTooltip>
              <Button
                href="https://www.instagram.com/chase_guitars"
                target="_blank"
                className="btn-round btn-icon"
                color="default"
                id="tooltip340339231"
                size="lg"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip340339231">
                Follow me on Instagram
              </UncontrolledTooltip>
            </div>
            <h3 className="title">About me</h3>
            <h5 className="description">
              An artist of considerable range, Chase — giving it a warm, intimate feel
              with attention to details and a solid groove structure of building a custom guitar.
              <br/>  <br/>
              ACDC Signature SG replica with some further customizations.
              A solid Sheoak body, Sheoak and carbon fiber set tennon neck, with a Jarrah fretboard, 
              Gold lightning bolt inlays, Roller bridge, Locking tuners, 
              Original Gibson Classic '57 and '57 Plus Humbuckers, 
              Traditional gold volume and pot knobs and wiring setup.
            </h5>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h4 className="title text-center">My custom guitars</h4>
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                         id="tooltip515203352901"
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        <i className="fas fa-rocket"></i>
                      </NavLink>
                      <UncontrolledTooltip delay={0} target="tooltip515203352901">
                      Larina SG
                    </UncontrolledTooltip>
                    </NavItem>
                    <NavItem>
                      <NavLink
                       id="tooltip515203352902"
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <i className="now-ui-icons media-2_note-03"></i>
                      </NavLink>
                      <UncontrolledTooltip delay={0} target="tooltip515203352902">
                      Tiger Lily
                    </UncontrolledTooltip>
                    </NavItem>
                    <NavItem>
                      <NavLink
                       id="tooltip515203352903"
                        className={pills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        <i className="fas fa-guitar"></i>
                      </NavLink>
                      <UncontrolledTooltip delay={0} target="tooltip515203352903">
                      Nick Mazoni
                    </UncontrolledTooltip>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <TabContent className="gallery" activeTab={"pills" + pills}>
                <TabPane tabId="pills1">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                    <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/larina-sg1.jpg").default}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/larina-sg4.jpg").default}
                        ></img>
                      </Col>
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/larina-sg5.jpg").default}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/larina-sg6.jpg").default}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="pills2">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                    <Col md="6">
                        <img
                          alt="32 Frets"
                          className="img-raised"
                          src={require("assets/img/32fret1.jpg").default}
                        ></img>
                        <img
                          alt="32 Frets"
                          className="img-raised"
                          src={require("assets/img/32fret2.jpg").default}
                        ></img>
                      </Col>
                      <Col md="6">
                        <img
                          alt="32 Frets"
                          className="img-raised"
                          src={require("assets/img/32fret3.jpg").default}
                        ></img>
                        <img
                          alt="32 Frets"
                          className="img-raised"
                          src={require("assets/img/32fret4.jpg").default}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="pills3">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/nick-mazoni1.jpg").default}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/nick-mazoni2.jpg").default}
                        ></img>
                      </Col>
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/nick-mazoni3.jpg").default}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/nick-mazoni4.jpg").default}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>

        <div className="section home-section">
          <Container>
          <div className="button-container">
              <Button className="btn-round" color="info" size="lg"      href="/guitars">
                More to see on Gallery
              </Button>
          </div>
          </Container>
        </div> 
        <DarkFooter />
      </div>
          
           
      </>
    );
  }
  
  export default Home;
  /*

          <video id="bgvid" playsInline autoPlay muted loop>
            <source src={require("assets/vid/guitar1.mp4").default} type="video/mp4"/>
            </video> 
            <Container  className="themed-container" fluid={true}>
            <Row>
              <Col>
                  <div className="main-body home-screen" >
                   <h1>Welcome</h1>
                  </div>
              </Col>
            </Row>
            <Row>
              <Col>
              <Container className="themed-container"><GalleryShow /></Container>
                    
                 
              </Col>
            </Row>
       
            </Container>

  */